import { Pagination, Stack, Typography } from "@mui/material";
import { useIntl } from "gatsby-plugin-intl";
import React from "react";

const AcademicsPagination = ({ pagination, title }) => {
    const intl = useIntl();

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}
        >
            <Typography
                component='p'
                variant='body2'
                align='center'
                paddingRight={1}
            >
                {intl.formatMessage({ id: "academics.pagination.showing" })}
                {pagination().rangeItems()}
                {intl.formatMessage({ id: "academics.pagination.of" })}
                {pagination().countNbAcademics()}
                {intl.formatMessage({ id: "academics.pagination.academics" })}
            </Typography>

            <Pagination
                count={pagination().count}
                size="small"
                page={pagination().pagePagination}
                variant="outlined"
                shape="rounded"
                showFirstButton
                showLastButton
                onChange={pagination().handleChange}
                aria-label={title}
            />
        </Stack>
    )
};

export {
    AcademicsPagination
};

