import { Stack } from "@mui/material";
import Seo from "components/common/Seo";
import Layout from "components/layout/Layout";
import { injectIntl } from "gatsby-plugin-intl";
import React, { useCallback, useEffect, useState } from "react";
import academicService from "services/api/academicService";
import BackToAcademics from "../common/BackToAcademics";
import AcademicDetail from "./components/AcademicDetail";

const AcademicDetailPage = ({ intl, academicId }) => {

    const [academic, setAcademic] = useState();

    const loadServerRows = useCallback(async () => {

        const data = await academicService.searchAcademicById(
            academicId
        );
        let content = data.data;
        setAcademic(content);
    }, []);

    useEffect(() => {
        loadServerRows();
    }, []);
    let title = intl.formatMessage({ id: "academic.seo.title" });
    if (academic && academic.firstName && academic.lastName) {
      title += " " + academic.firstName + " " + academic.lastName;
    }
    return (
        <Layout>
            <Seo
                title={title}
                lang={intl.locale}
            />
            <Stack spacing={2}>
                <BackToAcademics expertise=""/>
                <AcademicDetail academic={academic} />
            </Stack>
        </Layout>
    );
};

export default injectIntl(AcademicDetailPage);
