import React, { useEffect, useState } from 'react';

import { useIntl } from 'gatsby-plugin-intl';
import { navigate } from 'gatsby';

import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

import Layout from 'components/layout/Layout';
import Seo from 'components/common/Seo';
import courseService from 'services/api/courseService';
import CourseInfo from 'components/catalog/course/CourseInfo';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';


const BackButton = () => {
    const intl = useIntl();
    const goBack = () => {
        navigate(-1);
    };
    return (    
        <Button variant='contained' color='primary' sx={{ marginBottom: '15px' }}
            onClick={ goBack } >
            <ArrowBackIosNewIcon sx={{ verticalAlign: 'middle' }} /> { intl.formatMessage({id: "common.button.goBack"}) }
        </Button>
    );
};


const CourseDetailPage = ({ courseId, sessionId }) => {
    const intl = useIntl();
    const [title, setTitle] = useState(intl.formatMessage({id: "course.catalog"}));
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const {data} = await courseService.searchById(courseId);
                setTitle(data.title);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.error('Error fetching course title: ', error);
                navigate("/back-office/courses");
            }  
        };
        if (!!courseId) {
            fetchData();
        }
    }, [courseId]);

    return (
        <Layout inverseBackground={true}>
            <Seo
                title={title}
                lang={intl.locale}
            />
            <BackButton/>
            { loading ?
                <Box justifyContent={'center'} display={'flex'}>
                    <CircularProgress />
                </Box>
                :
                <CourseInfo courseId={courseId} sessionId={sessionId}></CourseInfo>
            }             
        </Layout>
    );
};

export default CourseDetailPage;

