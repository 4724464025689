import _ from "lodash";
import { WSInstance } from "./axios_config";

const LIST_OF_VALUE_API_BASE_URL = `${process.env.API_URL}/`;

const searchExpertiseById = async (id) => {
  return await WSInstance.get(`${LIST_OF_VALUE_API_BASE_URL}expertiseById/${id}`, { params: id });
};

const searchDisciplineById = async (id) => {
  return await WSInstance.get(`${LIST_OF_VALUE_API_BASE_URL}disciplineById/${id}`, { params: id });
};

const searchCountrieResearchById = async (id) => {
  return await WSInstance.get(`${LIST_OF_VALUE_API_BASE_URL}countrieResearch/${id}`, { params: id });
};

const searchWorkingLanguagesById = async (id) => {
  return await WSInstance.get(`${LIST_OF_VALUE_API_BASE_URL}workingLanguages/${id}`, { params: id });
};

const findUpcomingCoursesNotEnrolledByUserId = async (userId) =>  {
  return await WSInstance.get(`${process.env.API_URL}/upcoming-courses/${userId}`, { params: userId });
};

const listOfValueService = {
  searchExpertiseById,
  searchDisciplineById,
  searchCountrieResearchById,
  searchWorkingLanguagesById,
  findUpcomingCoursesNotEnrolledByUserId
};

export default listOfValueService;
