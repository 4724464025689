import React, { useEffect, useState } from "react";
import {ImageList, ImageListItem, useMediaQuery} from "@mui/material";
import { universities } from "utils/constants/constants";
import themeMuiV5 from "../../common/ThemeMuiV5";

const CourseLogo = ({ course }) => {

    const styles= {
        img : {
            maxWidth: "80px ",
            height: "40px"
        }
    }
    let [image, setImage] = useState("");
    let [images, setImages] = useState([]);
    let [imageAlt, setImageAlt] = useState({});

    const isUnder = useMediaQuery(themeMuiV5.breakpoints.down('xs'))
    const columnsNbr = isUnder ? 2 : 3

    useEffect(() => {
        let univ = universities.find(univ => univ.acronym === course?.universityAcronym);
        if (typeof univ !== "undefined" && univ !== null) {
            setImage(univ.img);
            setImageAlt(univ.name);
        }

        let arr = [];
        if (course && course?.universityAcronymMulti
            && course.universityAcronymMulti.length > 0) {
            course.universityAcronymMulti.map((acr) => {
                let tempUniv = universities.find(univ => univ.acronym === acr)
                if (typeof tempUniv !== "undefined" && tempUniv !== null) {
                    arr.push({img: tempUniv.img, alt : tempUniv.name});
                }
            })
        }

        setImages(arr);
    }, [course]);

        switch (course.logo) {
            case 'UNIVERSITY': {
                return !course.multiJoinCode ?
                    <img src={image} alt={"logo " + imageAlt} style={styles.img}/> 
                    :
                        images?.length > 0 ? (
                            <ImageList sx={{ height: 'auto'}} cols={columnsNbr}>
                                { images.sort().map((item, i) => (
                                    
                                    <ImageListItem key={item.img}>
                                        <img src={item.img} alt={"logo " + item.alt} loading="lazy" style={styles.img}/>
                                    </ImageListItem>

                                ))}
                            </ImageList>)
                            :   
                                ''
            }
            case 'CIVICA':
                return <img src={"/logo/220/civica_220-110.png"} alt={"logo civica"} style={styles.img}/>
            case 'NONE':
                return '';
            default :
                return <img src={image} alt={"logo " + imageAlt} style={styles.img} />

        }
};
export default CourseLogo;