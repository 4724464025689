import React, {Fragment} from 'react';

import { Card, CardContent, CardMedia, Chip, Grid, Typography } from '@mui/material';

import { useIntl } from 'gatsby-plugin-intl';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import courseService from 'services/api/courseService';

import themeMuiV5 from 'components/common/ThemeMuiV5';

const NewsDetail = ({ news, height='100%' }) => {
    return (
        <>
        {
            news ? 
                <News news={news} height={height} /> 
                : 
                <></>
        }
        </>
    );
};

const News = ({ news, height='100%' }) => {

    const intl = useIntl();
    return (
        <Card style={{ height: height }}>
            {
                (news?.media?.stream || news?.coverImageUrl) && 
                <CardMedia 
                    image={news?.media?.stream ? `data:image/jpeg;base64,${news?.media?.stream}` : news?.coverImageUrl}
                    component="img"
                    title={news?.title}
                    sx={{
                        height: 300,
                    }}
                />
            }
            {
                news && 
                <CardContent>
                    <Typography gutterBottom variant="h6" component="h2"
                        sx={{
                            color: 'black'
                        }}
                    >
                        {news.publishedFrom && new Date(news?.publishedFrom).toLocaleDateString(intl.locale)}
                        {news?.keywords?.map((keyword, i) =>
                            <Chip key={i} variant={"outlined"} 
                                size="small"
                                label={intl.formatMessage({
                                    id: courseService.getLabelKeyByGroupAndCode(
                                        null,
                                        keyword
                                    ),
                                })}
                                sx={{
                                    float: 'right',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    border: 'none',
                                    color: 'black',
                                }}

                                />
                        )}
                    </Typography>
                    <Typography variant="body2" component="p"
                        color="textSecondary"
                        sx={{
                            color: '#244f9d',
                        }}
                    >
                        {news?.title}
                    </Typography>
                    <Typography component={'span'} variant={'body2'} >
                        {

                            news && news.contentMarkdown ? parse(draftToHtml(JSON.parse(news.contentMarkdown))) : null
                        }
                    </Typography>
                    {news.deadline && <Typography component={'p'} variant={'body2'} 
                        sx={{
                            fontWeight: themeMuiV5.typography.fontWeightBold,
                        }}
                        
                    >
                    {intl.formatMessage({id:"opportunity.deadline.date.texte"}) + news.deadline}
                    </Typography>}
                </CardContent>
            }
        </Card>
    )
}

export default NewsDetail;
