import React from "react";
import courseService from "services/api/courseService";
import { GROUP } from "utils/constants/back-office-constants";
import { CATALOG_FIELDS } from "utils/constants/courses-constants";

import { useIntl } from "gatsby-plugin-intl";
import { Box, Typography } from "@mui/material";
import themeMuiV5 from 'components/common/ThemeMuiV5';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

const CourseType = ({ courseType, courseSubType, isEngage, pageFields }) => {
    const intl = useIntl();
    return (
    <Box sx={{ marginTop: '10px' }}>    
    {       
        (courseType || courseSubType) ?
        <Typography variant="body1" paragraph>
            <FontAwesomeIcon
                icon={faBriefcase}
                color={themeMuiV5.palette.primary.main}
            />

                <Typography component="span"
                sx={{marginLeft: themeMuiV5.spacing(1),}}
                >
                {(!courseSubType && courseType && pageFields?.includes(CATALOG_FIELDS.courseType)) ? (
                            intl.formatMessage({
                                id: courseService.getLabelKeyByGroupAndCode(
                                    GROUP.courseType,
                                    courseType
                                )
                            }))
                        : (courseSubType && pageFields?.includes(CATALOG_FIELDS.courseSubtype)) ?
                            (intl.formatMessage({
                                id: courseService.getLabelKeyByGroupAndCode(
                                    GROUP.courseSubtype,
                                    courseSubType
                                )
                            })) : ''
                    } {(isEngage)?(' ('+intl.formatMessage({id: "course.engage.course"}) + ')'):''}
                </Typography>
        </Typography> 
        : 
        isEngage ?
            <Typography variant="body1" paragraph>
                <FontAwesomeIcon
                    icon={faBriefcase}
                    color={themeMuiV5.palette.primary.main}
                />
                <Typography component="span"
                    sx={{ marginLeft: themeMuiV5.spacing(1), }} 
                >  {'('  + intl.formatMessage({id: "course.engage.course"}) + ')'}
                </Typography>
            </Typography> 
        : 
        ''        
    }
     </Box>   
    );
};

export default CourseType;