import { Link, Stack, Typography } from "@mui/material";
import themeMuiV5 from "components/common/ThemeMuiV5";
import { useIntl } from "gatsby-plugin-intl";
import React from "react";

const AcademicDetailTitle = ({ title, paddingTop = true, maringBottom = false }) => {
    const intl = useIntl();

    let sxPaddingTop = (paddingTop) ? themeMuiV5.spacing(3) : 0;
    let sxMargingBottom = (maringBottom) ? themeMuiV5.spacing(1) : 0;

    return (
        <Typography component='h6' pt={sxPaddingTop} mb={sxMargingBottom}
            sx={{
                borderBottom: 'solid 1px',
                color: themeMuiV5.palette.primary.main
            }}
        >
            {intl.formatMessage({ id: title })}
        </Typography>
    )
};

const AcademicPublications = ({ id, publication }) => {
    return (
        <Stack direction="row" spacing={1} pt={1} key={`publicationRow-${id}`}>
            -&nbsp; <Link target={"_blank"} href={publication.link} key={`publicationLink-${id}`} rel="noopener" variant="body1">{publication.title}</Link>
        </Stack>
    )
};

export {
    AcademicDetailTitle, AcademicPublications
};

