import { Typography } from "@mui/material";
import React from "react";


const NoResult = ({ intl }) => {
    return (
        <Typography align='center'>
            {intl.formatMessage({ id: "common.label.no.result" })}
        </Typography>
    )
};

export {
    NoResult
};