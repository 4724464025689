import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { Grid } from '@mui/material';
import {injectIntl, navigate} from 'gatsby-plugin-intl';

import Seo from 'components/common/Seo';
import Layout from 'components/layout/Layout';
import {universities} from 'utils/constants/constants';
import EDUGainEntry from 'components/login/EDUGainEntry';
import { isLoggedIn } from 'services/auth';


const useStyles = makeStyles((theme) => ({
      root: {
        flexGrow: 1,
      },
      paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        width: '100%',
        color: theme.palette.primary.main,
      },
      gridItem: {
        width: '100%',         
      },    
}))

const LoginPage = ({intl}) => {
    const classes = useStyles();
    if(isLoggedIn()){
        navigate("/");
    }
    return (
        <Layout>
            <Seo title={intl.formatMessage({ id: "login.title" })}></Seo>
            <h3>
                {intl.formatMessage({ id: "login.page-title" })} <br />
                {intl.formatMessage({ id: "login.authentication-action-text" })}
            </h3>
            <Grid container spacing={3} className={classes.paper}>
        
            {
                universities.filter(ui => !ui.hideLogin).map((ui, idx) => {
                    return (
                        <Grid item key={idx} className={classes.gridItem}
                        xs={12} sm={6} md={2.4}>
                            <EDUGainEntry key={idx} pp={ui}></EDUGainEntry>
                        </Grid>
                    )
                })
            }
            </Grid>
        </Layout>
    )
}

export default injectIntl(LoginPage);
