import React, { useEffect, useState } from 'react';

import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material';
import Calendar from 'react-calendar';
import moment from 'moment';
import { makeStyles } from '@mui/styles';

import UpcomingSessionList from 'components/catalog/UpcomingSessionList';
import UserDocumentList from './UserDocumentList';
import { useIntl } from 'gatsby-plugin-intl';
import themeMuiV5 from 'components/common/ThemeMuiV5';
import NewsDetail from 'components/news/NewsDetail';
import useScreenSize from "components/common/useScreenSize";


const checkAvailableSessions = (sessions) => {
    return sessions
        .filter(
            session => {
                let sessionEndTime = moment(new Date(session.endTime))
                return sessionEndTime.isAfter(new Date());
            }).length > 0;
};  
  
const SidePanel = ({ courses, sessions, onSessionClick, userId=null,  news=null, height='auto'  }) => {
  
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const screenHeight = useScreenSize().height;
    const hasSessions = sessions?.length > 0 && checkAvailableSessions(sessions);
  
      const styles = {
          newsDetail: {
              height: isMobile ? 'auto' : screenHeight > 700 ? height: 'auto',
          },
          upcomingSessionList: {
              homepage:{
                  height: isMobile ? 'auto' : screenHeight > 700 ? height: 'auto',
                  minHeight: '550px',
              }
          },
          documentList: {
              card: {
                  height: isMobile ? 'auto' : screenHeight > 700 ? hasSessions ? `calc(${height} - 320px)`: 'auto' : 'auto',
                  minHeight: 'auto',
                  marginTop: isMobile ? '20px' : '0px'
              }, 
              cardHeader: {
                  textAlign: 'center',
                  backgroundColor: themeMuiV5.palette.primary.main,
                  color: themeMuiV5.palette.primary.contrastText
              },
              cardContent: {
                  padding: '10px 10px 24px !important',
                  height: '100%',
                  overflowY: 'auto'
              }
          }
      }
  
      const useMakeStyles = makeStyles(() => ({
          calendar: {
              height: '300px',
              marginTop: isMobile ? '20px' : '0px',
              marginBottom: '20px',
          }
      }));
      const sidePanelStyles = useMakeStyles();
      
      const intl = useIntl();
      const [calendarDate, setCalendarDate] = useState(new Date());
      const [calendarSessionDays, setCalendarSessionDays] = useState([]);
      const [filteredSessions, setFilteredSessions] = useState(sessions);
  
      const filterSessions = (date) => {
        const upcomingSessions = sessions?.filter(
                  session => {
                      let sessionEndTime = moment(new Date(session.endTime))
                      let eventDate = moment(date);
                      return sessionEndTime.isAfter(eventDate);
                  });
          setFilteredSessions(upcomingSessions);
      }
  
      useEffect(() => {
          let mounted = true;
          const fetchData = async () => {
              setLoading(true);
              try {
                  const sessionDays = sessions?.map((element) => (moment(new Date(element.startTime))).format('DD-MM-YYYY'));
                  if(mounted) {
                      setCalendarSessionDays(sessionDays);
                      filterSessions(new Date());
                  }
              } catch (error) {
                  console.log('Error fetching data', error);
              } finally {
                  setLoading(false);
              }
              
          };
          fetchData();
          return () => {
              mounted = false;
          }
      }, [sessions]);
  
      return (
        <>
        {
            loading ?
            <Box style={{ display: 'flex', height: '100vh', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>    
            :
            <Grid container columnSpacing={2}>
              <Grid item xs={12} lg={8}>
                  { hasSessions ?
                      <UpcomingSessionList 
                          sessions={filteredSessions}
                          courses={courses}
                          loading={loading}
                          onSessionClick={onSessionClick}
                          height={styles.upcomingSessionList.homepage.height} 
                      />
                      :
                      <NewsDetail news={news}  height={styles.newsDetail.height} />
                  }
              </Grid>
  
              <Grid item xs={12} lg={4}>
                  { (hasSessions && calendarSessionDays?.length > 0)  &&
                      <Calendar
                          locale={intl.locale}
                          className={sidePanelStyles.calendar}
                          tileClassName={({date}) => {
                              if (calendarSessionDays.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                                  return 'highlight'
                              }
                          }}
                          value={calendarDate}
                          onChange={(date) => {
                              setCalendarDate(date);
                              filterSessions(new Date(date))
                          }}
                      />
                  }
                  {
                      userId && 
                      <Card sx={styles.documentList.card}>
                          <CardHeader
                              title={intl.formatMessage({ id: "myDocuments.title" })}
                              sx={styles.documentList.cardHeader}
                          />
                          <CardContent sx={styles.documentList.cardContent}>
                              <UserDocumentList userId={userId}/>
                          </CardContent>
                      </Card>
                  }
              </Grid>
          </Grid>
        }
       </>   
      );   
  };

export default SidePanel;
