import _ from "lodash";
import {WSInstance} from "./axios_config";

const MAILS_API_BASE_URL = `${process.env.API_URL}/mails`;


const sendMail = async (mail) => {
    const config = {withCredentials: true, headers: {"Content-Type": "multipart/form-data"}}
    const attachments = mail.files ? mail.files : [];
    const json = JSON.stringify(mail);
    const formData = new FormData();
    const blob = new Blob([json], {
        type: 'application/json'
    });
    formData.append('mail', blob);
    attachments.forEach(file => {
        formData.append('files', file);
    })
    return await WSInstance.post(`${MAILS_API_BASE_URL}/send`, formData, config);
};

const selectForCurrentUser = async () => {
    try {
        return await WSInstance.get(`${MAILS_API_BASE_URL}/user`, {withCredentials: true});

    } catch (error) {
        throw error;
    }
}
const selectByUserId = async (userId) => {
    try {
        return await WSInstance.get(`${MAILS_API_BASE_URL}/user/${userId}`, {withCredentials: true});

    } catch (error) {
        throw error;
    }
}

const selectByAcademicId = async (academicId) => {
    try {
        return await WSInstance.get(`${MAILS_API_BASE_URL}/academic/${academicId}`, {withCredentials: true});

    } catch (error) {
        throw error;
    }
}

const canUserContactAcademic = async (academicId) => {
    try {
        return await WSInstance.get(`${MAILS_API_BASE_URL}/academic/${academicId}/authorized`, {withCredentials: true});

    } catch (error) {
        throw error;
    }
}
const selectSentToAcademicId = async (academicId) => {
    try {
        return await WSInstance.get(`${MAILS_API_BASE_URL}/sent/academic/${academicId}`, {withCredentials: true});

    } catch (error) {
        throw error;
    }
}

const findByPrimaryKey = async (mailId) => {
    try {
        return await WSInstance.get(`${MAILS_API_BASE_URL}/${mailId}`, {withCredentials: true});

    } catch (error) {
        throw error;
    }
}

const mailService = {
    sendMail, selectForCurrentUser, selectByUserId, selectByAcademicId, canUserContactAcademic,selectSentToAcademicId,
    findByPrimaryKey
};

export default mailService;
