import React, { Fragment } from 'react';

import { Card, Grid, Typography } from '@mui/material';

import themeMuiV5 from 'components/common/ThemeMuiV5';
import { injectIntl, useIntl } from 'gatsby-plugin-intl';


const WelcomeAcademics = () => {
    const intl = useIntl();

    return (
        <Fragment>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Card
                    sx={{
                        backgroundColor: themeMuiV5.palette.background.paper,
                        textAlign: "center",
                        paddingTop: themeMuiV5.spacing(1),
                        color: themeMuiV5.palette.primary.main
                    }}
                >
                    <Typography variant="h6" component="h1" gutterBottom>
                        {intl.formatMessage({ id: "academics.welcome" })}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {intl.formatMessage({ id: "academics.description" })}
                    </Typography>
                </Card>
            </Grid>
        </Fragment>
    );
};

export default injectIntl(WelcomeAcademics);
