import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import LanguageIcon from '@mui/icons-material/Language';
import { Icon, Link, Stack } from "@mui/material";
import React, { Fragment } from "react";

const LogoOrcid = () => {
    return (
        <Fragment>
            <Icon>
                <img src='/logo/information/ORCIDiD_icon24x24.png' />
            </Icon>
        </Fragment>)
};

const LogoTwitter = () => {
    return (
        <Fragment>
        <Icon>
            <img src='/logo/information/icon_twitter.png' width={'24px'} height={'24px'} />
        </Icon>
    </Fragment>)
};

const LogoLinkedIn = () => {
    return (
        <Fragment>
        <Icon>
            <img src='/logo/information/icon_linkedin.png' width={'24px'} height={'24px'} />
        </Icon>
    </Fragment>)
};

const LogoWebPage = () => {
    return (
        <Fragment>
            <LanguageIcon color="primary" />
        </Fragment>)
};

const LogoBiblio = () => {
    return (
        <Fragment>
            <AutoStoriesIcon color="primary" />
        </Fragment>)
};

const LogoGoogleScholar = () => {
    return (
        <Fragment>
            <Icon>
                <img src='/logo/information/32px-Google_Scholar_logo.svg.png' width={'24px'} height={'24px'} />
            </Icon>
        </Fragment>)
};

const LogoResearchGate = () => {
    return (
        <Fragment>
            <Icon>
                <img src='/logo/information/32px-ResearchGate_icon_SVG.svg.png' width={'24px'} height={'24px'} />
            </Icon>
        </Fragment>)
};


const LogoWithLink = ({ code, link, logo, descr, id }) => {
    return (
        <Stack direction="row" spacing={1} pt={1}>
             {(logo)?logo():""} 
             <Link target={"_blank"} href={link} key={id} rel="noopener" variant="body1">{descr}</Link>
         </Stack>
    )
};


export {
    LogoWithLink, LogoOrcid, LogoTwitter, LogoWebPage, LogoLinkedIn, LogoBiblio, LogoGoogleScholar, LogoResearchGate
};

