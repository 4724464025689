import themeMuiV5 from 'components/common/ThemeMuiV5';
import { injectIntl } from 'gatsby-plugin-intl';
import React from 'react';

const { Card, CardHeader, CardContent, Button } = require("@mui/material");

const ButtonLetterDisable = ({ letter }) => {
    return (
        <Button
            size="small"
            id={`buttonDisableId-${letter}`}
            key={`buttonDisable-${letter}`}
            disabled
            sx={{
                minWidth: '45px'
            }}
        >{letter}</Button>
    )
};

const ButtonLetterEnable = ({ searchLetter, letter, onClickSearchLetter }) => {
    function letterButtonVariant(letter) {
        let variant = "text";

        if ((!searchLetter && !letter) || (searchLetter == "All" && letter == "All")) {
            variant = "contained";
        } else if (searchLetter && searchLetter == letter) {
            variant = "contained";
        }

        return variant
    }

    return (
        <Button
            size="small"
            id={`buttonEnableId-${letter}`}
            key={`buttonEnable-${letter}`}
            onClick={(e) => onClickSearchLetter(e, letter)}
            variant={letterButtonVariant(letter)}
            sx={{
                minWidth: '45px'
            }}
        >{letter}</Button>
    )
};

const SearchLetterList = ({ intl, academicsLetter, searchLetter, onClickSearchLetter }) => {

    const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    return (
        <Card sx={{ backgroundColor: themeMuiV5.palette.background.paper }} key="SearchByLetter">
            <CardHeader
                title={intl.formatMessage({ id: "academics.filter.title" })}
                key={'headerSearchLetter'}
                sx={{
                    textAlign: "center",
                    backgroundColor: themeMuiV5.palette.primary.main,
                    color: themeMuiV5.palette.primary.contrastText,
                    padding:0
                }}
            >
            </CardHeader>
            <CardContent
                key={'contentSearchLetter'}
                sx={{
                    backgroundColor: themeMuiV5.palette.background.paper,
                    padding: 1,
                    '&:last-child': { pb: 1 }
                }}>
                {(academicsLetter && academicsLetter.length > 0) ?
                    <ButtonLetterEnable searchLetter={searchLetter} letter="All" onClickSearchLetter={onClickSearchLetter} key="buttonEnable-All" /> :
                    <ButtonLetterDisable letter="All" key="buttonDisable-All" />
                }
                {
                    letters.map((letter, i) =>
                        (academicsLetter && academicsLetter?.length > 0 && academicsLetter.find(academic => academic?.toUpperCase().startsWith(letter))) ?
                            <ButtonLetterEnable searchLetter={searchLetter} letter={letter} onClickSearchLetter={onClickSearchLetter} key={`buttonEnable-${letter}`} /> :
                            <ButtonLetterDisable letter={letter} key={`buttonDisable-${letter}`} />
                    )
                }
            </CardContent>
        </Card>
    );
}

export default injectIntl(SearchLetterList);