import React, { useState } from 'react';

import { CircularProgress, Container, Grid } from '@mui/material';

import {getUser} from 'services/api/requests';
import {getNewsById} from 'services/api/newsService';
import Layout from 'components/layout/Layout';
import WelcomeMessage from 'components/common/WelcomeMessage';
import NewsDetail from 'components/news/NewsDetail';
import NewsList from 'components/news/NewsList';

const NewsPage = ({newsId}) => {
    const initialUser = {
        cvcUser: {
            courses:[]
        },
        samlUserDetails: {}
    }
    let [news, setNews] = useState({});
    let [user, setUser] = useState(initialUser);
    const [loading, setLoading] = useState(false);

    React.useEffect(() => {
        setLoading(true);
        async function fetchData() {
            try {
                let user = await getUser();
                setUser(user.data)
                let news = await getNewsById(newsId);
                setNews(news.data)
            } catch (error) {
            }
        };

        fetchData();
        setLoading(false);
    }, [newsId,setNews]);

    return (
        <Layout>
                <Grid container spacing={3} >
                    <WelcomeMessage user={user} />
                    <Grid item xs={12} lg={4}>
                        <NewsList user={user} height='100%'/>
                    </Grid>
                    <Grid item xs={12} lg={8} sx={{ width: '100%'}}>
                    {loading ?
                        <CircularProgress /> :
                        <NewsDetail news={news} height='100%'/>
                    }
                    </Grid>
                </Grid>
        </Layout>
    )
}

export default NewsPage;
