import React from 'react';

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Grid, Link, Typography } from '@mui/material';
import { navigate } from "gatsby";
import { useIntl } from 'gatsby-plugin-intl';
import themeMuiV5 from 'components/common/ThemeMuiV5';

const BackToAcademics = ({ expertise }) => {
    const intl = useIntl();
    const backToHomePage = `/${intl.locale}/faculty`;

    return (
        <Grid item lg={12} md={12} sm={12} xs={12}
        >
            <Typography gutterBottom>
                <KeyboardBackspaceIcon color="primary" sx={{ verticalAlign: 'middle' }} />
                <Link
                    component="button"
                    variant="body1"
                    onClick={() => { navigate(-1) }} >
                    {intl.formatMessage({ id: "academics.back.previous" })}
                </Link>
                {" - "}
                <Link
                    component="button"
                    variant="body1"
                    onClick={() => { navigate(backToHomePage) }}>
                    {intl.formatMessage({ id: "academics.back.home" })}
                </Link>
            </Typography>
            {(expertise) ?
                <Typography component="h6" gutterBottom sx={{ color: themeMuiV5.palette.primary.main}}>
                    {expertise.label}
                </Typography> : ''
            }
        </Grid>
    );
};

export default BackToAcademics;
