import React, { Fragment } from 'react';

import { Button, Card, CardActions, CardContent, Divider, Grid, Stack } from '@mui/material';

import { injectIntl } from 'gatsby-plugin-intl';

import { RhfAutocompleteListOfValuesSelect, RhfListOfValuesSelect, RhfRadioButtonAllRow, RhfTextField } from 'components/backoffice/common/RhfFields';
import useClasses from 'components/common/useClasses';
import themeMuiV5 from 'components/common/ThemeMuiV5';

import { GROUP } from 'utils/constants/back-office-constants';
import AcademicsListSearchLetter from './AcademicsListSearchLetter';
import academicService from "services/api/academicService";
import { ListOfValues } from 'ui/ListOfValues';

const styles = theme => ({
    textField: {
        marginTop: themeMuiV5.spacing(1),
        width: "100%",
    },
});

const SearchEngineAcademics = ({ intl, methods, resetForm, submitForm, academicsLetter, searchLetter, onClickSearchLetter, defaultValueList}) => {

    const classes = useClasses(styles);
    const [unitList, setUnitList] = React.useState([]);
    const [firstLoadUnit, setFirstLoadUnit] = React.useState(true);

    const handleSubmitForm = (formData) => {
        submitForm(formData);
    };

    const onChangeUniversity = async (university) => {
        methods.setValue('academicUnit', "");
        setFirstLoadUnit(false);
        await updateUniversityUnitList(university.value);
    }

    const updateUniversityUnitList = async (universityId) => {
        if (universityId) {
            await academicService.findUnitsWithAcademicsByUniversityId(universityId)
                .then(response => {
                    const univUnitList = response.data;
                    if (univUnitList) {
                        setUnitList(univUnitList);
                    } else {
                        console.log("no university units for university id : ", universityId);
                    }
                }).catch(error => {
                    console.log("error : ", error);
                });
        } else {
            await academicService.findUnitsWithAcademics()
                .then(response => {
                    const univUnitList = response.data;
                    if (univUnitList) {
                        setUnitList(univUnitList);
                    } else {
                        console.log("no university units");
                    }
                }).catch(error => {
                    console.log("error : ", error);
                });
        }
    }

    React.useEffect(() => {
        if(firstLoadUnit) updateUniversityUnitList();
    }, [firstLoadUnit]);
    
    return (
        <Fragment>
                <Grid item>
                    <AcademicsListSearchLetter academicsLetter={academicsLetter} onClickSearchLetter={onClickSearchLetter} searchLetter={searchLetter} />
                </Grid>
                <Grid item mt={1} sx={{ width: "100%" }}>
                    <form onSubmit={methods.handleSubmit(handleSubmitForm)} noValidate>

                        <Card
                            sx={{
                                maxHeight: '100%',
                                backgroundColor: themeMuiV5.palette.background.paper}}
                        >
                            <CardContent
                                sx={{backgroundColor: themeMuiV5.palette.background.paper}}
                            >
                                <Stack
                                    direction="column"
                                    spacing={2}
                                >
                                    <RhfTextField
                                        name="textFilter"
                                        label={"academics.filter.searchFullText"} 
                                        control={methods.control}
                                        id="filter-search-fullText"
                                        fullWidth
                                    />
                                     <RhfAutocompleteListOfValuesSelect
                                        name={"disciplines"}
                                        label={"academics.filter.discipline"}
                                        group={GROUP.disciplineFieldWithAcademic}
                                        defaultValue={defaultValueList.discipline}
                                        control={methods.control}
                                        setValue={methods.setValue}
                                        className={classes.textField}
                                        id="filter-Disciplines"
                                    />
                                    <RhfAutocompleteListOfValuesSelect
                                        name={"expertises"}
                                        label={"academics.filter.expertise"}
                                        group={GROUP.expertiseFieldWithAcademic}
                                        defaultValue={defaultValueList.expertise}
                                        control={methods.control}
                                        setValue={methods.setValue}
                                        className={classes.textField}
                                        id="filter-Expertites"
                                    />
                                    <RhfListOfValuesSelect
                                        name="academicsUniversity"
                                        label={"academics.filter.university"}
                                        group={GROUP.universityAllWithAcademic}
                                        control={methods.control}
                                        className={classes.textField}
                                        id="filter-university-select"
                                        onChangeField={onChangeUniversity}
                                    />
                                    <ListOfValues
                                        name="academicUnit"
                                        label={"academics.filter.researchCenter"}
                                        items={unitList}
                                        group={GROUP.researchCenterUniversityWithAcademic}
                                        control={methods.control}
                                        className={classes.textField}
                                        id="filter-academics-unit-select"
                                    />
                                    <RhfAutocompleteListOfValuesSelect
                                        name={"countriesResearch"}
                                        label={"academics.filter.countriesResearch"}
                                        group={GROUP.countriesResearchFieldWithAcademic}
                                        defaultValue={defaultValueList.countrieResearch}
                                        control={methods.control}
                                        setValue={methods.setValue}
                                        className={classes.textField}
                                        id="filter-CountriesResearch"
                                    />
                                    <RhfAutocompleteListOfValuesSelect
                                        name={"workingLanguage"}
                                        label={"academics.filter.workingLanguage"}
                                        group={GROUP.workingLanguageFieldWithAcademic}
                                        defaultValue={defaultValueList.workingLanguage}
                                        control={methods.control}
                                        setValue={methods.setValue}
                                        className={classes.textField}
                                        id="filter-WorkingLanguage"
                                    />
                                    <RhfRadioButtonAllRow
                                    name={"alreadyContacted"}
                                    control={methods.control}
                                    label={"academics.filter.alreadyContacted.label"}
                                    id="filter-alreadyContacted-radio"
                                    defaultValue={methods.alreadyContacted}
                                />
                                </Stack>
                            </CardContent>
                            <Divider style={
                                {
                                    marginTop: '10px'
                                }
                            } />

                            <CardActions
                                sx={{ justifyContent: 'center' }}
                            >
                                <Button onClick={() => resetForm()} color="secondary">
                                    {intl.formatMessage({ id: "academics.filter.reset" })}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    {intl.formatMessage({ id: "academics.filter.search" })}
                                </Button>
                            </CardActions>

                        </Card>
                    </form>
                </Grid>
        </Fragment>
    )
};

export default injectIntl(SearchEngineAcademics);